import { Icon } from '../icon';
import type { IconProps } from '../icon';

export function IconArrowLeftMd(props: IconProps) {
	return (
		<Icon {...props}>
			<path
				fill="none"
				stroke="currentColor"
				stroke-linecap="round"
				stroke-linejoin="round"
				stroke-width="2"
				d="M19 12H5m0 0l6 6m-6-6l6-6"
			/>
		</Icon>
	);
}
